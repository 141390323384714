module.exports = {
  siteUrl: `https://thinksaveretire.com`, // Site domain. Do not include a trailing slash!

  postsPerPage: 18, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: `ThinkSaveRetire.com: Early Retirement, Financial Independence and being a Digital Nomad - Think Save Retire`, // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: `What you need to read right now. Your complete guide to a high-income retirement You’re in the prime of your career and earning good money. But, your job stinks. It provides no satisfaction. Where do you go from here? In this guide, you&#8217;ll find out exactly how high-income early retirement happens.   Click here to read &hellip;`, // This allows an alternative site description for meta data for pages.

  shareImageWidth: `1000`, // Change to the width of your default share image
  shareImageHeight: `523`, // Change to the height of your default share image

  shortTitle: `Think Save Retire`, // Used for App manifest e.g. Mobile Home Screen
  siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: `#ffffff`, // Used for Offline Manifest
  themeColor: `#FFFFFF`, // Used for Offline Manifest
};
